<template>
  <b-container class="d-flex flex-column align-items-center justify-content-center mx-0 px-0 p-0 root-layout"  fluid>
   <div>
     <b-img
           src="@/assets/Figa_reglog.png"
           class="Figalogo-img"
           alt="Figalogo"
         ></b-img>
   </div>
   
   <b-card 
   body-class="text-center"
   style="max-width: 70rem; border-radius: 14.4px;"
   class="mt-4"
 >
  <div class = "login-view" >
         <b-img
           src="@/assets/Administrator-icon.png"
           class="logo-img"
           alt="Finance"
         ></b-img>
       
      
       <b-row
         class="d-flex flex-row align-items-center justify-content-center "
         no-gutters
       >
         <b-col >
           <h3 class="text-center text-main-green font-primary font-weight-bold">
             Password Reset
           </h3>

           <h5 class="text-center text-main-green font-primary font-weight-normal">
             Please enter your email address, we will send a verification code
           </h5>
         
         </b-col>
        
       </b-row>

     <form id="PasswordRest-form">
      <b-row
          class="d-flex flex-row align-items-center justify-content-center"
          no-gutters
        >
          <b-col >
            <FormInput
              groupId="user-name-group"
              id="user-name-group-input"
              class="text-prime-gray rounded-2"
              label="Email Account"
              :state="emailError.status"
              :invalidFeedback="`${emailError.message}`"
              v-model="email"
              placeholder="user@example.com"
              isRequired
              :whiteBG="false"
              trim
              form="PasswordRest-form"
              type="text"
            ></FormInput>
           
          
          </b-col>
        </b-row>
    
     
     <b-row flex-row align-items-center justify-content-center style="padding-top: 65px;" >
 <b-col d-flex flex-row align-items-center justify-content-center align-self="center" >
           <FormButton
              isBlock
              isPill
              variant="main-green"
              class="font-weight-normal text-secondary mt-2 login-button"
              type="submit"
          
        
              ><span class="text-white">Submit</span>
            </FormButton>
 </b-col>
 <b-col d-flex flex-row align-items-center justify-content-center align-self="center">
           <b-link
             to="/"
             class="font-primary font-weight-normal text-main-green"
             >Remember Password ?</b-link
           >
         </b-col>
</b-row>
    
 
</form>
 
   </div>
 </b-card>
   
 </b-container>
</template>

<script>
import FormInput from "@/components/Form/FormInput";
import FormButton from "@/components/Form/FormButton";
import CheckEmail from "@/util/CheckEmail";
export default {
 name: "ForgotPassword",
 components: {
    FormInput,
   FormButton,
  },
 data() {
     return {
    
         email: '',
         emailError: {
        status: null,
        message: "",
      },
   
     }
   },
   watch: {
    email(value) {
      
      if (value) {
        if (CheckEmail(value) == true) this.emailError.status = true;
        else {
          this.emailError.status = false;
          this.emailError.message = "Please enter a valid email address";
        }
      } else {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }
    },
  },
  computed: {
    isFormValid() {
      return this.email !== "";
    },
  },
   
}
</script>

<style scoped>
.root-layout {
   position: relative;
   min-height: 100vh;
   width: 100%;
   top: 0;
   left: 0;
  
   background-color: #FFE400;
 }

 .logo-img{
 align-items: center;
 width: 54px;
 height: 54px;
 

}
.root-layout .Figalogo-img{
 align-items: center;
 width: 180px;
 height: 60px;

}
.login-view{
  padding-left: 70px;
 padding-right: 70px;
 padding-top: 36px;
 padding-bottom: 50px;
 min-width: 30vw;
}
h5{
 font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 18px;
text-align: center;
padding-bottom: 25.6px;

/* Grey 2 */

color: #828282;
}
h3{
 font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 36px;
text-align: center;
padding-top: 14px;
/* Color/Green */

color: #0B9B7D;
}

.login-view .login-button{
width: 162px;
height: 50px;
background: #0B9B7D;
border-radius: 30px;

}
</style>
