var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form-group',{class:[
    _vm.localValue ? (_vm.localValue.toString().length > 0 ? 'is-filled is-focus' : '') : '',
    _vm.localValue && _vm.counter ? (_vm.localValue.toString().length > _vm.counter ? 'is-over-count' : '') : '',
    _vm.isFocused ? 'is-focus' : '',
    _vm.whiteBG ? 'white-label-bg' : '',
    _vm.isDisabled ? 'is-disabled' : '',
    _vm.counter ? 'with-counter' : '',
  ],attrs:{"id":_vm.groupId,"label":_vm.label,"label-for":_vm.id,"label-class":"form-label","description":_vm.description,"invalid-feedback":_vm.invalidFeedback,"state":_vm.state}},[(_vm.isTextArea)?_c('b-form-textarea',{ref:_vm.id,attrs:{"id":_vm.id,"type":_vm.type,"required":_vm.isRequired,"placeholder":_vm.isFocused ? _vm.placeholder : '',"state":_vm.state,"rows":"6","max-rows":"9","trim":_vm.trim,"form":_vm.form,"disabled":_vm.isDisabled,"value":_vm.value},on:{"input":(e) => _vm.updateInput(e),"blur":(e) => _vm.localChangeFn(e),"focus":(e) => _vm.onFocus(e)}}):_c('b-form-input',{ref:_vm.id,attrs:{"id":_vm.id,"type":_vm.type,"required":_vm.isRequired,"placeholder":_vm.isFocused ? _vm.placeholder : '',"state":_vm.state,"trim":_vm.trim,"form":_vm.form,"value":_vm.value,"disabled":_vm.isDisabled},on:{"input":(e) => _vm.updateInput(e),"blur":(e) => _vm.localChangeFn(e),"focus":(e) => _vm.onFocus(e)}}),(_vm.counter)?_c('span',{staticClass:"input-counter"},[_vm._v(_vm._s(`${_vm.localValue ? _vm.localValue.toString().length : 0}/${_vm.counter}`))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }